import React from 'react'

import { Col, Divider, Inline, Paragraph, Row, Text } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Divider"
    components={[{ component: Divider }]}
  >
    <Section>
      <Playground>
        <Divider />
      </Playground>
      <Playground>
        <Divider variant="sidebar" />
      </Playground>
      <Playground format="html">
        <div className="divider" />
      </Playground>
    </Section>
    <Section title="Vertikaalinen">
      <Playground>
        <Row>
          <Col>
            <Text align="center">
              Lorem ipsum
              <br />
              Dolor sit amet
            </Text>
          </Col>
          <Col xs="auto">
            <Divider
              noMargin
              variant="vertical"
            />
          </Col>
          <Col>
            <Text align="center">
              Lorem ipsum
              <br />
              Dolor sit amet
            </Text>
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col text-center">
            Lorem ipsum
            <br />
            Dolor sit amet
          </div>
          <div className="col-auto">
            <div className="divider-vertical m-0" />
          </div>
          <div className="col text-center">
            Lorem ipsum
            <br />
            Dolor sit amet
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Inline vertical">
      <Playground>
        <Inline>
          <Text>Vasen</Text>
          <Divider variant={'inlineVertical'} />
          <Text>Oikea</Text>
        </Inline>
      </Playground>
    </Section>
    <Section title="Marginaalit">
      <SectionHeading>
        <Code>marginBefore</Code> ja <Code>marginAfter</Code>
      </SectionHeading>
      <Playground>
        <>
          Lorem ipsum
          <Divider
            marginAfter={'sm'}
            marginBefore={'sm'}
          />
          Lorem ipsum
          <Divider
            marginAfter={'xl'}
            marginBefore={'xl'}
          />
          Lorem ipsum
          <Divider
            marginAfter={'xxxs'}
            marginBefore={'xxxs'}
          />
          Lorem ipsum
          <Divider
            marginAfter={'xxxs'}
            marginBefore={'xl'}
          />
          Lorem ipsum
        </>
      </Playground>
      <SectionHeading>
        <Code>noMargin</Code>
      </SectionHeading>
      <Paragraph>
        Jos komponentille annetaan sekä <Code>marginBefore</Code> ja{' '}
        <Code>marginAfter</Code> propit, että <Code>noMargin</Code>,{' '}
        <Code>noMargin</Code> ylikirjoittaa marginaalien arvot nollaksi.
      </Paragraph>
      <Playground>
        <>
          Lorem ipsum
          <Divider noMargin />
          Lorem ipsum
          <Divider
            marginAfter={'sm'}
            marginBefore={'sm'}
            noMargin
          />
          Lorem ipsum
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page

import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { StyledRow } from '../Grid/Row.style.js';

const getBackgroundColor = color => {
  if (color === 'primary') {
    return tokens.color['primary-lightest'];
  }
  if (color === 'secondary') {
    return tokens.color['secondary-lightest'];
  }
  if (color === 'success') {
    return tokens.color['success-lightest'];
  }
  if (color === 'danger') {
    return tokens.color['danger-lightest'];
  }
  if (color === 'white') {
    return tokens.color.white;
  }
  return tokens.color['neutral-3'];
};
const getColor = (color, disabled) => {
  if (disabled) {
    return tokens.color['neutral-6'];
  }
  if (color === 'primary') {
    return tokens.color['primary-dark'];
  }
  if (color === 'secondary') {
    return tokens.color['secondary-dark'];
  }
  if (color === 'success') {
    return tokens.color['success-dark'];
  }
  if (color === 'danger') {
    return tokens.color['danger-dark'];
  }
  return tokens.color['neutral-9'];
};
const StyledBadge = styled.span.withConfig({
  componentId: "sg1720__sc-uv1fej-0"
})(["align-items:center;background-color:", ";border-radius:", ";box-shadow:", ";box-shadow:", ";color:", ";display:inline-flex;font-weight:", ";hyphens:auto;justify-content:center;line-height:", ";min-width:", ";outline:none;padding:", " ", ";text-decoration:none;transition:box-shadow ", ";vertical-align:middle;&:hover{color:", ";}&:focus{box-shadow:", ";}", " ", "{flex:1 0 100%;}"], props => getBackgroundColor(props.$color), tokens.radius.lg, props => props.$bordered && !props.$borderColor && "inset 0 0 0 1px ".concat(tokens.color['neutral-5']), props => props.$borderColor && "inset 0 0 0 1px ".concat(tokens.color[props.$borderColor]), props => getColor(props.$color, props.$disabled), tokens['font-weight'].normal, tokens['line-height'].xs, tokens.space.xxl, tokens.space.xxs, tokens.space.xs, tokens.transition.fast, props => getColor(props.$color, props.$disabled), tokens.shadow.focus, props => props.$block && css(["display:flex;width:100%;"]), StyledRow);
const StyledBadgeIconContainer = styled.span.withConfig({
  componentId: "sg1720__sc-uv1fej-1"
})(["color:", ";display:flex;flex:0 0 auto;margin-right:0.25rem;"], props => getColor(props.$color, props.$disabled));
const StyledBadgeDivider = styled.span.withConfig({
  componentId: "sg1720__sc-uv1fej-2"
})(["align-self:stretch;border-left:1px solid ", ";flex:0 0 auto;margin-bottom:0.25rem;margin-top:0.25rem;width:1px;"], tokens.color['neutral-5']);

export { StyledBadge, StyledBadgeDivider, StyledBadgeIconContainer };

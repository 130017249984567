import React, { ReactNode, useState } from 'react'

import {
  Badge,
  Box,
  Button,
  Buttons,
  Col,
  ColumnConnector,
  Columns,
  CrownIcon,
  EmptyState,
  GridList,
  Heading,
  Inline,
  InlineList,
  InlineListItem,
  Link,
  Main,
  Paragraph,
  RibbonIcon,
  Row,
  SkipLink,
  SmallText,
  Spacer,
  Spinner,
  TaskList,
  TaskListItem,
  Text,
  useInterval,
  Wrapper
} from '@te-digi/styleguide'

import { LinkMock } from '../../../components/LinkMock'
import { TimelineExample1 } from '../../../pages/listat-ja-taulukot/timeline'

import { HeaderExample } from '../HeaderExamples'
import { FooterExample } from '../FooterExample'

interface ProfileBoxProps {
  children: ReactNode
  heading: string
}

const SUGGESTED_JOBS = [
  {
    heading: 'Levyseppähitsaaja',
    list: ['Tampere', 'kokoaikatyö']
  },
  {
    heading: 'Muurari',
    list: ['Pirkkala', 'kokoaikatyö']
  },
  {
    heading: 'Hitsaajan apulainen',
    list: ['Pirkkala', 'kokoaikatyö']
  },
  {
    heading: 'Hitsaajan apulainen',
    list: ['Pirkkala', 'kokoaikatyö']
  },
  {
    heading: 'Timpuri',
    list: ['Ikaalinen', 'osa-aikatyö']
  },
  {
    heading: 'Sähköasentaja',
    list: ['Kuru', 'osa-aikatyö']
  },
  {
    heading: 'Timpuri',
    list: ['Kuru', 'osa-aikatyö']
  },
  {
    heading: 'Timpuri',
    list: ['Kuru', 'osa-aikatyö']
  }
]

const ProfileBox = ({ children, heading }: ProfileBoxProps) => (
  <Spacer marginBottom="lg">
    <Box
      border="none"
      color="light"
      noMargin
      padding="lg"
    >
      <Row>
        <Col>
          <Heading
            align={{ xs: 'center', sm: 'left', xl: 'center' }}
            level={3}
            size={4}
          >
            <Text color="primary">{heading}</Text>
          </Heading>
        </Col>
        <Col
          sm="auto"
          xl={12}
        >
          <Spacer marginBottom={{ xs: 'lg', sm: 0, xl: 'lg' }}>
            <Text align={{ xs: 'center', sm: 'left', xl: 'center' }}>
              <Button>Muokkaa</Button>
            </Text>
          </Spacer>
        </Col>
      </Row>
      {children}
    </Box>
  </Spacer>
)

const ProfileExample = () => {
  const [isLoading, setIsLoading] = useState(true)

  useInterval(() => {
    setIsLoading(false)
  }, 2000)

  return (
    <>
      <SkipLink />
      <Wrapper>
        <HeaderExample
          activePrimaryItem="Omat sivut"
          activeSecondaryItem="Työnhakuprofiili"
          hasUser
        />
        <Columns layout="wide">
          <Main>
            <Spacer marginBottom="lg">
              <Inline
                alignItems="center"
                gapX="lg"
                gapY="sm"
                wrap="wrap"
              >
                <Heading noMargin>Työnhakuprofiili</Heading>
                <Badge>Julkaistu - Voimassa 5.4.2020 saakka</Badge>
              </Inline>
            </Spacer>
            {isLoading ? (
              <Spinner align="center" />
            ) : (
              <>
                <Spacer marginBottom="md">
                  <Row>
                    <Col xl={8}>
                      <Paragraph>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Saepe ullam consequatur molestias eum accusantium
                        debitis illum, consectetur minus alias ut quas, totam
                        earum voluptatem itaque dicta eos quo mollitia harum.
                      </Paragraph>
                    </Col>
                    <Col xl={4}>
                      <Row
                        justifyContent="center"
                        gap="md"
                      >
                        <Col
                          xs={12}
                          sm="auto"
                          xl={12}
                        >
                          <Spacer marginBottom="md">
                            <Text align={{ xs: 'center', lg: 'right' }}>
                              <Button variant="outline">
                                Katsele työnhakuprofiiliasi
                              </Button>
                            </Text>
                          </Spacer>
                        </Col>
                        <Col
                          xs={12}
                          sm="auto"
                          xl={12}
                        >
                          <Text align={{ xs: 'center', lg: 'right' }}>
                            <Button variant="outline">
                              Muokkaa avainsanojasi
                            </Button>
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Spacer>
                <Spacer marginBottom="md">
                  <Row justifyContent="center">
                    <Col xs="auto">
                      <CrownIcon
                        block
                        color="primary"
                        size="layout-lg"
                      />
                    </Col>
                    <Col xs={12}>
                      <Heading
                        align="center"
                        level={2}
                        size={4}
                        noMargin
                      >
                        <Text color="primary">
                          "Motivoitunut rakennusammattilainen"
                        </Text>
                      </Heading>
                    </Col>
                  </Row>
                </Spacer>
                <ColumnConnector />
                <Row>
                  <Col xl={4}>
                    <Spacer marginBottom="lg">
                      <Box
                        noMargin
                        padding="lg"
                      >
                        <Row>
                          <Col>
                            <Heading
                              align={{
                                xs: 'center',
                                sm: 'left',
                                xl: 'center'
                              }}
                              level={3}
                              size={4}
                            >
                              <Text color="primary">Osaaminen</Text>
                            </Heading>
                          </Col>
                          <Col
                            xs={12}
                            sm={{ size: 12, order: 2 }}
                            xl={{ size: 12, order: 0 }}
                          >
                            <Spacer marginBottom="lg">
                              <EmptyState
                                icon={<RibbonIcon />}
                                title="Ei lisätty osaamisia"
                              />
                            </Spacer>
                          </Col>
                          <Col
                            sm="auto"
                            xl={12}
                          >
                            <Spacer
                              marginBottom={{
                                xs: 'lg',
                                sm: 'none',
                                xl: 'lg'
                              }}
                            >
                              <Text
                                align={{
                                  xs: 'center',
                                  sm: 'right',
                                  xl: 'center'
                                }}
                              >
                                <Button>Lisää uusi</Button>
                              </Text>
                            </Spacer>
                          </Col>
                        </Row>
                        <Text
                          align="center"
                          color="dark"
                        >
                          Saat parempia työpaikkaehdotuksia, kun kerrot
                          osaamisestasi ja mm. kielitaidostasi,
                          vapaaehtoistyöstä ja luottamustoimistasi.
                        </Text>
                      </Box>
                    </Spacer>
                  </Col>
                  <Col xl={4}>
                    <ProfileBox heading="Esittely ja työtoiveet">
                      <TaskList>
                        <TaskListItem status="complete">
                          <Paragraph noMargin>
                            <Text fontWeight="semi-bold">Kuka olen</Text>
                          </Paragraph>
                          <SmallText noMargin>
                            2 ammattia, 15 osaamista
                          </SmallText>
                        </TaskListItem>
                        <TaskListItem status="complete">
                          <Paragraph noMargin>
                            <Text fontWeight="semi-bold">Työtoiveet</Text>
                          </Paragraph>
                          <SmallText noMargin>1 ammatti, 5 osaamista</SmallText>
                        </TaskListItem>
                        <TaskListItem>
                          <Paragraph noMargin>
                            <Text fontWeight="semi-bold">Työskentelyajat</Text>
                          </Paragraph>
                        </TaskListItem>
                        <TaskListItem status="complete">
                          <Paragraph noMargin>
                            <Text fontWeight="semi-bold">Yhteystiedot</Text>
                          </Paragraph>
                          <SmallText noMargin>matti@sahkoposti.fi</SmallText>
                        </TaskListItem>
                        <TaskListItem>
                          <Paragraph noMargin>
                            <Text fontWeight="semi-bold">Linkit</Text>
                          </Paragraph>
                        </TaskListItem>
                      </TaskList>
                    </ProfileBox>
                  </Col>
                  <Col xl={4}>
                    <ProfileBox heading="Työkokemus ja koulutus">
                      <TimelineExample1 />
                    </ProfileBox>
                  </Col>
                </Row>
                <Heading
                  level={2}
                  size={3}
                >
                  Työpaikkaehdotuksia
                </Heading>
                <GridList
                  gap="sm"
                  sm={2}
                  lg={3}
                  xl={4}
                >
                  {SUGGESTED_JOBS.map((job, i) => (
                    <Box
                      key={i}
                      noMargin
                      padding="lg"
                    >
                      <Link as={LinkMock}>
                        <Heading
                          level={3}
                          noMargin
                          size={5}
                        >
                          {job.heading}
                        </Heading>
                      </Link>
                      <Spacer marginTop="xs">
                        <Text
                          color="dark"
                          size="sm"
                        >
                          <InlineList>
                            {job.list.map(item => (
                              <InlineListItem key={item}>{item}</InlineListItem>
                            ))}
                          </InlineList>
                        </Text>
                      </Spacer>
                    </Box>
                  ))}
                </GridList>
                <Buttons
                  align="center"
                  noMargin
                >
                  <Button variant="outline">Näytä kaikki ehdotukset</Button>
                </Buttons>
              </>
            )}
          </Main>
        </Columns>
        <FooterExample />
      </Wrapper>
    </>
  )
}

export { ProfileExample }

import React, { useState } from 'react'

import {
  AlertCircleFillIcon,
  CirclePartialFillIcon,
  FeedATMTCard,
  HourglassIcon,
  Link,
  Paragraph,
  PathIcon,
  Stack,
  StatementIcon,
  StatementQuestionIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTNotaBeneExample = () => {
  const [cardExpanded, setCardExpanded] = useState(true)

  return (
    <Stack gap={'xs'}>
      <FeedATMTCard
        attentionColor={'warning'}
        description={{
          body: (
            <>
              <Paragraph>
                Työnantaja ja tehtävä: Ravintola Äx, Artisti
              </Paragraph>
              <Paragraph>
                Katsomme, että olet todisteellisesti riitauttanut irtisanomisen
                tai purkamisen kolmen kuukauden kuluessa työ- tai virkasuhteesi
                päättymisestä. Sinun tulee ilmoittaa omaan asiointitoimistoon,
                kun riita-asia on saanut lainvoimaisen päätöksen.
              </Paragraph>
            </>
          ),
          summary:
            'Työ- tai virkasuhteesi on päättynyt irtisanomiseen tai purkamiseen.'
        }}
        details={{
          status: {
            icon: <CirclePartialFillIcon color={'warning'} />,
            text: 'Valmis käsiteltäväksi'
          },
          textRows: [
            { label: 'Liittyvä päivä', content: '5.4.2023' },
            { label: 'Käsiteltävä viimeistään', content: '10.4.2023' }
          ]
        }}
        headingComponent={
          <Link
            as={LinkMock}
            to={'#'}
          >
            11 Irtisanoutunut työ- tai virkasuhteesta koeaikana
          </Link>
        }
        headingLevel={2}
        icon={<StatementIcon />}
        isExpanded={cardExpanded}
        toggleExpanded={() => setCardExpanded(!cardExpanded)}
      />
      <FeedATMTCard
        attentionColor={'warning'}
        description={{
          summary: 'Irtisanoutunut työ- tai virkasuhteesta koeaikana'
        }}
        details={{
          status: {
            icon: <AlertCircleFillIcon color={'warning'} />,
            text: 'Valmis käsiteltäväksi'
          },
          textRows: [{ label: 'Käsiteltävä viimeistään', content: '10.4.2023' }]
        }}
        headingComponent={
          <Link
            as={LinkMock}
            to={'#'}
          >
            Työttömyysetuusasian selvitys
          </Link>
        }
        headingLevel={2}
        icon={<StatementQuestionIcon />}
      />
      <FeedATMTCard
        attentionColor={'warning-lightest'}
        description={{
          summary:
            'Voimassa olevalla suunnitelmalla on tehtäviä, jotka odottavat asiakkaan toimia.'
        }}
        details={{
          status: {
            icon: <HourglassIcon color={'warning'} />,
            text: 'Odottaa asiakkaan toimia'
          },
          textRows: [
            { label: 'Ilmoitettava viimeistään', content: '15.4.2023' }
          ]
        }}
        headingComponent={
          <Link
            as={LinkMock}
            to={'#'}
          >
            Työllistymissuunnitelma
          </Link>
        }
        headingLevel={2}
        icon={<PathIcon />}
      />
    </Stack>
  )
}

export { FeedATMTNotaBeneExample }

import React from 'react'

import {
  FeedATMTCard,
  FeedATMTItem,
  FeedATMTSection,
  FeedATMTSubItem,
  Help,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

import { Content } from '../../components/Content'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { FeedATMTCardExample } from '../../examples/react/FeedATMTCardExample'
import { FeedATMTItemExample } from '../../examples/react/FeedATMTItemExample'
import { FeedATMTOnelineItemExample } from '../../examples/react/FeedATMTOnelineItemExample'
import { FeedATMTSubItemExample } from '../../examples/react/FeedATMTSubItemExample'
import { FeedATMTNotaBeneExample } from '../../examples/react/FeedATMTNotaBeneExample'
import { FeedATMTSectionExample } from '../../examples/react/FeedATMTSectionExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FeedATMT"
    components={[
      { component: FeedATMTSection, restElement: true },
      { component: FeedATMTItem, restElement: true },
      { component: FeedATMTSubItem, restElement: true },
      { component: FeedATMTCard, restElement: true }
    ]}
  >
    <Section>
      <Help>
        <Paragraph noMargin>
          Tämän sivun komponentit on suunniteltu käytettäväksi vain A-TMT:llä
          henkilöasiakkaan tapahtumavirran rakentamiseen. Komponentit TMT:n
          tapahtumavirtaa varten löytyvät{' '}
          <Code>
            <Link page="Feed">Feed</Link>
          </Code>{' '}
          -sivulta.
        </Paragraph>
      </Help>
    </Section>

    <Section title={'Tapahtumakortti'}>
      <Paragraph>
        Tapahtumavirta koostuu yksittäisistä tapahtumakorteista (
        <Code>FeedATMTCard</Code>).
      </Paragraph>
      <Paragraph noMargin>Kortti on jaettu kahteen pääosaan:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Sisältöalueella näytetään tapahtuman nimi (
          <Code>headingComponent</Code>) ja kuvaus (<Code>description</Code>).
        </ListItem>
        <ListItem>
          Lisätietoalueella (<Code>details</Code>) voidaan viestiä kortin
          tilasta (<Code>status</Code>) ja koota asiaan liittyviä päivämääriä
          tai muita lyhyitä otsikko-arvo-pareja (<Code>textRows</Code>)
        </ListItem>
      </List>
      <Paragraph noMargin>
        <Code>description</Code>-proppi jakautuu kahteen osaan:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Code>summary</Code> on lyhyt, enintään yhden virkkeen tiivis kuvaus
          tapahtuman sisällöstä. <Code>summary</Code> näytetään aina.
        </ListItem>
        <ListItem>
          <Code>body</Code> on tarvittaessa annettava <Code>summary</Code>:a
          täydentävä tarkempi kuvaus.
        </ListItem>
      </List>
      <Playground example={FeedATMTCardExample} />
    </Section>

    <Section title={'Avoimet asiat'}>
      <Paragraph>
        Avoimet asiat -osiossa käytetään <Code>FeedATMTCard</Code>-komponentteja
        ilman aikajanan luovaa <Code>FeedATMTItem</Code>-komponenttia.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Kortille määritellään aina asian tyypistä kertova <Code>icon</Code> ,
          sekä tilabadge (<Code>status</Code>) ja sitä vastaava korostus (
          <Code>attentionColor</Code>)
        </ListItem>
        <ListItem>
          Jos kortin <Code>description</Code>:lle on määritelty{' '}
          <Code>body</Code> tämä näytetään avoimet asiat -osiossa oletuksena
          avattuna.
        </ListItem>
      </List>

      <Playground example={FeedATMTNotaBeneExample} />
    </Section>

    <Section title={'Menneet tapahtumat'}>
      <Paragraph>
        Menneet tapahtumat -osiossa <Code>FeedATMTCard</Code>-komponentti on
        prop <Code>FeedATMTItem</Code>-komponentille, joka lisää kortille
        aikajanan.
      </Paragraph>
      <List variant={'unordered'}>
        <ListItem>
          Menneissä tapahtumissa asian tyypistä kertova icon annetaan kortin
          sijaan <Code>FeedATMTItem</Code>:lle, jolloin se näytetään
          aikajanalla.
        </ListItem>
        <ListItem>
          Jos kortin <Code>description</Code>:lle on määritelty{' '}
          <Code>body</Code> tämä näytetään Menneet tapahtumat -osiossa
          oletuksena suljettuna.
        </ListItem>
      </List>
      <Playground example={FeedATMTItemExample} />
    </Section>

    <Section title={'Alitapahtumat'}>
      <Paragraph>
        Menneeseen tapahtumaan voi liittyä alitatapahtumien (
        <Code>FeedATMTSubItem</Code>) ketjuja.
      </Paragraph>
      <List variant={'unordered'}>
        <ListItem>
          Alitapahtumaketjun viimeiselle kortille annetaan aina{' '}
          <Code>isLast</Code>-proppi, jotta ketjun sisennetty aikajana katkeaa
          ennen seuraava päätason tapahtumaa.
        </ListItem>
      </List>
      <Playground example={FeedATMTSubItemExample} />
    </Section>

    <Section title={'Yksirivinen kortti'}>
      <Paragraph>
        Yksirivisellä kortilla (<Code>FeedATMTOnelineItem</Code>) voidaan
        ilmaista vähemmän oleellisia tapahtumia, jotka voidaan kuvata yhdellä
        lyhyellä lauseella.
      </Paragraph>
      <Playground example={FeedATMTOnelineItemExample} />
    </Section>

    <Section title={'Aikajanan osio'}>
      <Paragraph>
        Menneiden tapahtumien aikajana jaetaan vuoden mittaisiin, avattaviin ja
        suljettaviin osioihin (<Code>FeedATMTSection</Code>).
      </Paragraph>
      <Playground example={FeedATMTSectionExample} />
    </Section>
  </Content>
)

export default Page

export default Object.freeze({
  "breakpoint": {
    "sm": "576px",
    "md": "768px",
    "lg": "992px",
    "xl": "1200px"
  },
  "color": {
    "dark": "#0a0a60",
    "primary": "#442496",
    "primary-lightest": "#e5ddf8",
    "primary-light": "#563eae",
    "primary-dark": "#311c81",
    "secondary": "#af3bbd",
    "secondary-lightest": "#f6def9",
    "secondary-light": "#be4cd2",
    "secondary-dark": "#892e99",
    "danger": "#dd005a",
    "danger-lightest": "#fddae4",
    "danger-light": "#ef3477",
    "danger-dark": "#c60055",
    "success": "#49ba4c",
    "success-lightest": "#caefcb",
    "success-light": "#66c568",
    "success-dark": "#1a751c",
    "warning": "#f36b16",
    "warning-lightest": "#ffe0ce",
    "warning-light": "#ffb386",
    "warning-dark": "#b24400",
    "link-on-light": "#764ceb",
    "link-on-dark": "#9b80ff",
    "link-on-light-hover": "#1F61FF",
    "link-on-dark-hover": "#5789ff",
    "neutral-1": "#fcfbff",
    "neutral-2": "#f6f4f9",
    "neutral-3": "#f0edf3",
    "neutral-4": "#d6d0d9",
    "neutral-5": "#bab2bf",
    "neutral-6": "#968e9d",
    "neutral-7": "#766d80",
    "neutral-8": "#5d5567",
    "neutral-9": "#3e3647",
    "neutral-10": "#2a2233",
    "white": "#ffffff"
  },
  "font-size": {
    "sm": "0.875rem",
    "md": "1rem",
    "lg": "1.125rem",
    "xl": "1.25rem",
    "xxl": "1.5rem",
    "xxxl": "1.75rem",
    "xxxxl": "2rem",
    "xxxxxl": "2.5rem",
    "xxxxxxl": "3rem"
  },
  "font-weight": {
    "normal": "400",
    "semi-bold": "600"
  },
  "gradient": {
    "content": "linear-gradient(#8b39b3, #442496)",
    "header": "linear-gradient(90deg, #8b39b3, #442496)",
    "light": "linear-gradient(#fff, #F6F4F9)"
  },
  "line-height": {
    "xs": "1.25",
    "sm": "1.5",
    "md": "1.75"
  },
  "radius": {
    "none": "0",
    "md": "0.25rem",
    "lg": "1rem",
    "circle": "50%",
    "pill": "20rem"
  },
  "shadow": {
    "dark": "0 2px 32px rgba(10, 10, 96, 0.75)",
    "card": "0 2px 32px rgba(0, 0, 0, 0.15)",
    "default": "0 2px 8px rgba(0, 0, 0, 0.1)",
    "focus": "0 0 0 3px rgba(82, 133, 255, 1)",
    "modal": "0 4px 10px rgba(0, 0, 0, 0.3)"
  },
  "space": {
    "xxxs": "0.125rem",
    "xxs": "0.25rem",
    "xs": "0.5rem",
    "sm": "0.75rem",
    "md": "1rem",
    "lg": "1.5rem",
    "xl": "2rem",
    "xxl": "2.5rem",
    "xxxl": "3rem",
    "layout-xxs": "1rem",
    "layout-xs": "1.5rem",
    "layout-sm": "2rem",
    "layout-md": "3rem",
    "layout-lg": "4rem",
    "layout-xl": "6rem",
    "layout-xxl": "10rem"
  },
  "transition": {
    "fast": "100ms ease",
    "medium": "200ms ease"
  },
  "z-index": {
    "dropdown": "1000",
    "sticky": "1020",
    "fixed": "1030",
    "modal-backdrop": "1040",
    "modal": "1050",
    "popover": "1060",
    "tooltip": "1070"
  }
})
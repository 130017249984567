import styled, { css, keyframes } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Inline } from '../Inline/Inline.js';

const StyledBadge = styled.div.withConfig({
  componentId: "sg1720__sc-wmoxyx-0"
})(["background-color:", ";border-radius:", ";content:'';height:", ";position:absolute;width:", ";"], tokens.color.secondary, tokens.radius.circle, tokens.space.sm, tokens.space.sm);
const StyledTab = styled.button.withConfig({
  componentId: "sg1720__sc-wmoxyx-1"
})(["align-items:center;border:none;border-radius:", ";color:", ";display:flex;font-size:", ";font-weight:", ";line-height:", ";outline:none;position:relative;text-decoration:none;text-transform:uppercase;transition:box-shadow 0.1s ease;white-space:nowrap;width:auto;&[aria-disabled=\"true\"]{color:", ";cursor:default;pointer-events:none;}", " &[aria-current=\"page\"],&[aria-selected=\"true\"]{", "}&:active{color:", ";}&:focus-visible{box-shadow:none;}&:hover:not(&[aria-current=\"page\"]):not(&[aria-selected=\"true\"]){color:", ";", " text-decoration:none;}&:hover:not(&[aria-current=\"page\"]):not(&[aria-selected=\"true\"]),&[aria-current=\"page\"],&[aria-selected=\"true\"]{", "}"], tokens.radius.md, props => {
  if (props.$color === 'danger') {
    return tokens.color.danger;
  } else if (props.$color === 'secondary') {
    return tokens.color.secondary;
  } else if (props.$variant === 'secondary') {
    return tokens.color['neutral-9'];
  } else {
    return tokens.color.primary;
  }
}, tokens['font-size'].sm, tokens['font-weight']['semi-bold'], tokens['line-height'].xs, tokens.color['neutral-6'], props => props.$variant === 'secondary' ? css(["border:1px solid transparent;border-bottom:0;border-bottom-left-radius:0;border-bottom-right-radius:0;padding:", " ", ";background-color:transparent;"], tokens.space.sm, tokens.space.md) : css(["padding:", ";background-color:", ";"], tokens.space.md, tokens.color.white), props => props.$variant === 'secondary' ? css(["background-color:", ";border-color:", ";color:", ";"], tokens.color.white, tokens.color['neutral-4'], tokens.color[props.$color || 'primary']) : css(["background-color:", ";color:", ";"], tokens.color[props.$color + '-lightest'], tokens.color[props.$color + '-dark']), props => {
  if (props.$color === 'danger') {
    return tokens.color['danger-dark'];
  } else if (props.$color === 'secondary') {
    return tokens.color['secondary-dark'];
  } else {
    return tokens.color['primary-dark'];
  }
}, props => {
  if (props.$color === 'danger') {
    return tokens.color['danger-light'];
  } else if (props.$color === 'secondary') {
    return tokens.color['secondary-light'];
  } else {
    return tokens.color['primary-light'];
  }
}, props => props.$variant !== 'secondary' && css(["background-color:", ";"], tokens.color['neutral-2']), props => props.$variant !== 'secondary' && css(["&::before{background-color:", ";bottom:0;content:'';display:block;left:0;position:absolute;z-index:1;border-radius:0 0 0.125rem 0.125rem;height:4px;right:0;top:auto;width:auto;}"], tokens.color[props.$color]));
const StyledTabsList = styled.div.withConfig({
  componentId: "sg1720__sc-wmoxyx-2"
})(["display:flex;list-style:none;margin:0;padding:0;scroll-behavior:smooth;bottom:0;flex-direction:row;left:0;overflow-x:auto;overscroll-behavior-x:contain;position:absolute;right:0;top:0;-webkit-overflow-scrolling:touch;&::-webkit-scrollbar{display:none;}-ms-overflow-style:none;scrollbar-width:none;"]);
const StyledInline = styled(Inline).withConfig({
  componentId: "sg1720__sc-wmoxyx-3"
})(["min-height:", ";", ":focus-visible &{border-radius:", ";box-shadow:", ";}"], tokens.space.lg, StyledTab, tokens.radius.md, tokens.shadow.focus);
const commonStyles = css(["bottom:0;content:'';display:block;left:0;position:absolute;right:0;"]);
const StyledTabs = styled.div.withConfig({
  componentId: "sg1720__sc-wmoxyx-4"
})(["margin-bottom:", ";position:relative;", " :focus,:focus-visible{outline:none;}"], tokens.space.lg, props => props.$variant === 'secondary' ? css(["height:3rem;&::before{", ";background-color:", ";height:1px;}"], commonStyles, tokens.color['neutral-4']) : css(["height:3.5rem;&::after{", ";background-color:", ";border-radius:2px;height:4px;}"], commonStyles, tokens.color['neutral-3']));
const tabArrowAnimation = keyframes(["0%{opacity:0;}100%{opacity:1;}"]);
const StyledPreviousTabs = styled.div.withConfig({
  componentId: "sg1720__sc-wmoxyx-5"
})(["animation:", " ", ";background-image:linear-gradient( to right,rgba(255,255,255,1) 50%,rgba(255,255,255,0) );bottom:1px;align-items:center;justify-content:flex-start;display:flex;left:0;position:absolute;top:0;width:", ";"], tabArrowAnimation, tokens.transition.medium, tokens.space['layout-lg']);
const StyledNextTabs = styled(StyledPreviousTabs).withConfig({
  componentId: "sg1720__sc-wmoxyx-6"
})(["background-image:linear-gradient( to left,rgba(255,255,255,1) 50%,rgba(255,255,255,0) );justify-content:flex-end;right:0;left:auto;"]);

export { StyledBadge, StyledInline, StyledNextTabs, StyledPreviousTabs, StyledTab, StyledTabs, StyledTabsList };

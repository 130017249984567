import React, { useState } from 'react'

import {
  Heading,
  Paragraph,
  TabList,
  TabListItem,
  TabPanel,
  useUniqueId
} from '@te-digi/styleguide'

const TAB_DATA = [
  {
    heading: 'Selvityspyynnöt ja lausunnot',
    panel: 'T001 Määräaikainen työsuhteesi on päättynyt'
  },
  {
    heading: 'Työssäolovelvoitteet',
    panel: 'Asiakkaalla ei ole voimassa olevia työssäolovelvoitteita.'
  },
  {
    heading: 'Maksajatiedot',
    panel: 'Työttömyysetuuden maksaja, voimassaolo, muokkaaja, muokkausaika'
  },
  {
    heading: 'Laiminlyönnit',
    panel: 'Laiminlyönneistä annetut lausunnot'
  },
  {
    heading: 'Kertymätiedot',
    panel: 'Maksupäivien kertymät'
  },
  {
    heading: 'Työttömyysetuus',
    panel: 'Kokoaikatyön ja etuuden hakeminen'
  }
]

const TabListSecondaryExample = () => {
  const [active, setActive] = useState(3)

  const uniqueId = useUniqueId()

  const numberOfItems = TAB_DATA.length

  const shiftSelection = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      setActive(active === numberOfItems - 1 ? 0 : active + 1)
    } else if (direction === 'left') {
      setActive(active === 0 ? numberOfItems - 1 : active - 1)
    }
  }

  return (
    <>
      <Heading
        level={2}
        id="heading-id-secondary-example"
      >
        Työttömyysturva
      </Heading>
      <TabList
        ariaLabelledby="heading-id-secondary-example"
        items={TAB_DATA.map((item, i) => (
          <TabListItem
            active={active === i}
            key={'tab-secondary-' + i}
            onClick={() => setActive(i)}
            tabIdentifier={uniqueId + '-' + i}
          >
            {item.heading}
          </TabListItem>
        ))}
        onSelectNext={() => shiftSelection('right')}
        onSelectPrevious={() => shiftSelection('left')}
        variant={'secondary'}
      />

      {TAB_DATA.map(
        (item, i) =>
          active === i && (
            <TabPanel
              key={'panel-secondary-' + i}
              tabIdentifier={uniqueId + '-' + i}
            >
              <Heading level={3}>{item.heading}</Heading>
              <Paragraph>{item.panel}</Paragraph>
            </TabPanel>
          )
      )}
    </>
  )
}

export { TabListSecondaryExample }

import React from 'react'

import {
  FeedATMTCard,
  FeedATMTItem,
  FeedATMTSubItem,
  Link,
  StatementIcon,
  StatementQuestionIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTSubItemExample = () => (
  <FeedATMTItem
    card={
      <FeedATMTCard
        description={{
          summary: 'Tiedote velvollisuuksien vastaisesta menettelystä lähetetty'
        }}
        details={{
          textRows: [{ label: 'Liittyvä päivä', content: '11.11.2012' }]
        }}
        headingComponent={
          <Link
            as={LinkMock}
            to={'#'}
          >
            Z001 Tiedote laiminlyönnistä
          </Link>
        }
        headingLevel={2}
      />
    }
    icon={<StatementIcon />}
    label={{ date: new Date('2012-12-12'), text: 'Annettu' }}
    subItems={[
      <FeedATMTSubItem
        card={
          <FeedATMTCard
            description={{
              summary:
                'Kieltäytyi tai jätti osallistumatta työkyvyn tutkimukseen tai arviointiin.'
            }}
            details={{
              textRows: [{ label: 'Liittyvä päivä', content: '2012-10-12' }]
            }}
            headingComponent={
              <Link
                as={LinkMock}
                to={'#'}
              >
                55 Kieltäytynyt työkyvyn tutkimuksesta
              </Link>
            }
            headingLevel={3}
          />
        }
        icon={<StatementQuestionIcon />}
        key={'item-1'}
        label={{ date: new Date('2012-11-11'), text: 'Käsitelty' }}
      />,
      <FeedATMTSubItem
        card={
          <FeedATMTCard
            description={{
              summary:
                'Kieltäytyi tai jätti osallistumatta työkyvyn tutkimukseen tai arviointiin.'
            }}
            details={{
              textRows: [{ label: 'Liittyvä päivä', content: '2012-9-11' }]
            }}
            headingComponent={
              <Link
                as={LinkMock}
                to={'#'}
              >
                55 Kieltäytynyt työkyvyn tutkimuksesta
              </Link>
            }
            headingLevel={3}
          />
        }
        icon={<StatementQuestionIcon />}
        isLast
        key={'item-2'}
        label={{ date: new Date('2012-10-12'), text: 'Käsitelty' }}
      />
    ]}
  />
)

export { FeedATMTSubItemExample }

import React from 'react'

import tyomarkkinatoriSvg from '@te-digi/styleguide/assets/tyomarkkinatori.svg'

const LogoHTMLExample = ({
  alt = 'Siirry etusivulle',
  className = 'logo-header',
  src = tyomarkkinatoriSvg
}: {
  alt?: string
  className?: string
  src?: string
}) => (
  <img
    alt={alt}
    className={className}
    src={src}
  />
)

export default LogoHTMLExample

import tokens from '@te-digi/styleguide-tokens'
import React, { useState } from 'react'

import {
  ArrowRightIcon,
  Button,
  CheckIcon,
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  Lead,
  Spacer
} from '@te-digi/styleguide'
import styled from 'styled-components'

import { LinkMock } from '../../components/LinkMock'

const StyledDiv = styled.div`
  background-color: ${tokens.color.primary};
  padding: 1rem;
`

const DropdownLanguageExample = () => {
  const [activeLang, setActiveLang] = useState<'fi' | 'sv' | 'en'>('fi')

  return (
    <StyledDiv>
      <Dropdown
        trigger={
          <Button
            color={'white'}
            variant={'plain'}
          >
            {activeLang === 'fi'
              ? 'Suomi'
              : activeLang === 'sv'
              ? 'Svenka'
              : 'English'}
          </Button>
        }
      >
        <DropdownSection disablePaddingBottom={true}>
          <Lead noMargin>Vaihda kieltä</Lead>
        </DropdownSection>
        <DropdownActions>
          <DropdownActionsItem
            as={'button'}
            icon={
              activeLang === 'fi' ? (
                <CheckIcon />
              ) : (
                <Spacer paddingRight={'lg'} />
              )
            }
            onClick={() => setActiveLang('fi')}
          >
            Suomi
          </DropdownActionsItem>
          <DropdownActionsItem
            as={'button'}
            icon={
              activeLang === 'sv' ? (
                <CheckIcon />
              ) : (
                <Spacer paddingRight={'lg'} />
              )
            }
            onClick={() => setActiveLang('sv')}
          >
            Svenska
          </DropdownActionsItem>
          <DropdownActionsItem
            as={'button'}
            icon={
              activeLang === 'en' ? (
                <CheckIcon />
              ) : (
                <Spacer paddingRight={'lg'} />
              )
            }
            onClick={() => setActiveLang('en')}
          >
            English
          </DropdownActionsItem>
        </DropdownActions>
        <DropdownDivider />
        <DropdownSection disablePaddingBottom={true}>
          <Lead noMargin>Tietoa muilla kielillä</Lead>
        </DropdownSection>
        <DropdownActions>
          <DropdownActionsItem
            as={LinkMock}
            iconRight={<ArrowRightIcon />}
            to="#"
          >
            Selkosuomi
          </DropdownActionsItem>
          <DropdownActionsItem
            as={LinkMock}
            iconRight={<ArrowRightIcon />}
            to="#"
          >
            Saame
          </DropdownActionsItem>
        </DropdownActions>
      </Dropdown>
    </StyledDiv>
  )
}

export { DropdownLanguageExample }

import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { BoxGroup } from '../Box/BoxGroup.js';
import { media } from '../utils/styles/media.js';

const StyledBoxGroup = styled(BoxGroup).withConfig({
  componentId: "sg1720__sc-1r5u7at-0"
})(["position:relative;&::before{border-bottom-left-radius:", ";border-bottom:1px solid ", ";border-left:1px solid ", ";content:'';height:", ";left:-0.5rem;position:absolute;top:-0.5rem;width:0.5rem;", "{height:", ";left:-1.5rem;top:", ";width:1.5rem;}}"], tokens.radius.md, tokens.color['neutral-4'], tokens.color['neutral-4'], props => {
  if (props.$onlyHeader) {
    if (props.$variant === 'compact') {
      return '1.25rem';
    } else {
      return '1.375rem';
    }
  } else if (props.$variant === 'compact') {
    return '2rem';
  } else {
    return '2.25rem';
  }
}, media.sm, props => {
  if (props.$onlyHeader) {
    if (props.$variant === 'compact') {
      return '1.25rem';
    } else {
      return '1.75rem';
    }
  } else if (props.$variant === 'compact') {
    return '2rem';
  } else {
    return '2.75rem';
  }
}, props => props.$variant === 'compact' ? '-0.5rem' : '-1rem');

export { StyledBoxGroup };

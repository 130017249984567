import React, { useState } from 'react'

import {
  FeedATMTCard,
  FeedATMTItem,
  FeedATMTSection,
  Link,
  StatementIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTSectionExample = () => {
  const [openSection, setOpenSection] = useState(true)

  return (
    <FeedATMTSection
      isOpen={openSection}
      itemCount={1}
      toggleOpen={() => setOpenSection(!openSection)}
      year={'2024'}
    >
      <FeedATMTItem
        card={
          <FeedATMTCard
            description={{
              summary:
                'Tiedote velvollisuuksien vastaisesta menettelystä lähetetty'
            }}
            details={{
              textRows: [{ label: 'Liittyvä päivä', content: '2024-11-11' }]
            }}
            headingComponent={
              <Link
                as={LinkMock}
                to={'#'}
              >
                Z001 Tiedote laiminlyönnistä
              </Link>
            }
            headingLevel={2}
          />
        }
        icon={<StatementIcon />}
        label={{ date: new Date('2024-12-12'), text: 'Annettu' }}
      />
    </FeedATMTSection>
  )
}

export { FeedATMTSectionExample }

import React from 'react'

import {
  FormError,
  FormGroup,
  Help,
  Input,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FormError"
    components={[{ component: FormError }]}
    status={[{ type: 'accessible', version: '16.2.0' }]}
  >
    <Section>
      <Help>
        <List noMargin>
          <ListItem>
            Lomakekomponenteilla on virheviestien näyttäminen
            sisäänrakennettuna. Muissa tapauksissa virheviestin näyttämiseen voi
            käyttää <Code>FormError</Code>-komponenttia.
          </ListItem>
          <ListItem>
            Lomakekenttä on yhdistettävä <Code>FormError</Code>
            -komponenttiin määrittelemällä <Code>id</Code>, sekä lomakekentälle
            vastaava <Code>ariaDescribedby</Code>.
          </ListItem>
        </List>
      </Help>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Input
            ariaDescribedby="form-text-example-1-2"
            ariaInvalid
            required
          />
        </FormGroup>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <input
            className="form-control form-control-danger"
            type="text"
            required
            aria-describedby="form-text-html-example-1"
          />
          <div
            aria-live="assertive"
            className="form-text text-danger"
            id="form-text-html-example-1"
          >
            Pakollinen tieto
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Lähtökohtaisesti syötteen ohjeet pitää kertoa ennen syötettä
          yläpuolella sublabelilla. <Code>FormError</Code>ia käytetään
          virheviestin näyttämiseen. Virhetekstiä ei saa näyttää ennen kuin
          käyttäjä on poistunut syötekentästä.
        </ListItem>
        <ListItem>
          Varmista, että virheviesti auttaa korjaamaan tehdyn virheen.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page

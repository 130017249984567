import React from 'react'

import { FormText, Help, List, ListItem } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FormText"
    components={[{ component: FormText }]}
  >
    <Section>
      <Help
        noMargin
        variant="error"
      >
        <List
          noMargin
          variant={'unordered'}
        >
          <ListItem>
            <Code>FormText</Code>-komponentti on tarkoitettu StyleGuiden
            sisäiseen käyttöön.
          </ListItem>
          <ListItem>
            Jos on tarve lisätä lomakkeelle virheviestejä, käytä{' '}
            <Code>FormError</Code>-komponenttia.
          </ListItem>
        </List>
      </Help>
    </Section>
  </Content>
)

export default Page

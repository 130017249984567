import React from 'react'

import {
  List,
  ListItem,
  Notifications,
  Paragraph,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { CodeBox } from '../../components/CodeBox'
import { Content } from '../../components/Content'
import { NotificationsExample1 } from '../../examples/react/NotificationsExample1'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Notifications"
    components={[{ component: Notifications }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        Ponnahdusilmoituksilla ilmoitetaan käyttäjän itse suorittamiin
        toimintoihin liittyvää palautetta, joka ei vaadi käyttäjän reagointia.
      </Paragraph>
      <Playground
        enableOverflow
        example={NotificationsExample1}
      />
    </Section>
    <Section title="Käyttö">
      <Paragraph>
        Ilmoitukset otetaan käyttöön lisäämällä <Code>Notifications</Code>
        -komponentti sovelluksen juureen viimeiseksi elementiksi. Ilmoitus
        näytetään <Code>addNotification</Code>-funktiolla.
      </Paragraph>
      <CodeBox language="tsx">
        addNotification('Content of Notification')
      </CodeBox>
    </Section>
    <Section title="Ominaisuudet">
      <Paragraph>
        Lisäominaisuuksia voi ottaa käyttöön antamalla{' '}
        <Code>addNotification</Code>
        -funktion toiselle argumentille objektin.
      </Paragraph>
      <CodeBox language="tsx">
        addNotification('Content of Notification', options)
      </CodeBox>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              scope="col"
              style={{ width: '25%' }}
            >
              Argumentti
            </TableHeaderCell>
            <TableHeaderCell scope="col">Kuvaus</TableHeaderCell>
            <TableHeaderCell
              scope="col"
              style={{ width: '10%' }}
            >
              Oletus
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <Code>autoClose</Code>
            </TableCell>
            <TableCell>
              <Code>boolean</Code>
              <br />
              Sulje notifikaatio automaattisesti 5 sekunnin kuluttua.
            </TableCell>
            <TableCell>
              <Code>true</Code>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Code>hidden</Code>
            </TableCell>
            <TableCell>
              <Code>boolean</Code>
              <br />
              Piilotettu notifikaatio ruudunlukijalle.
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Code>status</Code>
            </TableCell>
            <TableCell>
              <Code>'error'&nbsp;|&nbsp;'success'</Code>
              <br />
              Notifikaation tyyli.
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Notifikaatiot ovat ruudunlukijalle samanarvoisia (onnistuminen,
        epäonnistuminen, tavallinen), joten tekstistä tulee käydä selkeästi
        ilmi, mitä tapahtui.
      </Paragraph>
      <Paragraph>
        Komponentti käyttää aria-live-attribuutin arvoa polite (ei assertive).
        Tämä tarkoittaa, että ruudunlukija lukee ensisijaisesti käyttäjän
        aktiivista toimintaa, esimerkiksi navigointia. Notifikaatioteksti
        luetaan, kun käyttäjä on tekemättä mitään (idle) ja ruudunlukija on
        vapaana. (Huomioi, että Notifications-sivun esimerkit eivät vastaa
        todellista käyttötilannetta).
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että kriittistä tietoa sisältävät notifikaatiot eivät katoa
          automaattisesti.
        </ListItem>
        <ListItem>Notifikaation viesti ei saa olla liian pitkä.</ListItem>
        <ListItem>
          Kun notifikaatio suljetaan, fokuksen pitää siirtyä takaisin siihen
          elementtiin, jossa suoritettu toiminto aiheutti notifikaation.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page

import styled from 'styled-components';
import { Label } from './Label.js';
import { media } from '../utils/styles/media.js';

const StyledInlineLabel = styled.div.withConfig({
  componentId: "sg1720__sc-17zljm2-0"
})(["display:flex;flex-direction:column;width:100%;", "{align-items:center;flex-direction:row;}"], media.sm);
const StyledLabel = styled(Label).withConfig({
  componentId: "sg1720__sc-17zljm2-1"
})(["display:block;", "{flex:0 0 auto;margin-bottom:0;}"], media.sm);
const StyledInlineLabelChildren = styled.div.withConfig({
  componentId: "sg1720__sc-17zljm2-2"
})(["flex:1 1 auto;"]);

export { StyledInlineLabel, StyledInlineLabelChildren, StyledLabel };

import React from 'react'

import { FeedATMTOnelineItem, Link, PenIcon, Text } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTOnelineItemExample = () => (
  <FeedATMTOnelineItem
    date={new Date('2021-11-11')}
    description={<Text>Ulla Saarinen muokkasi yhteystietoja.</Text>}
    icon={<PenIcon />}
    link={<Link as={LinkMock}>Katso Perustiedot</Link>}
  />
)

export { FeedATMTOnelineItemExample }

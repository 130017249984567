import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { StyledButton } from '../Button/Button.style.js';
import { Text } from '../Text/Text.js';

const StyledInputGroupText = styled(Text).withConfig({
  componentId: "sg1720__sc-y3tk1n-0"
})(["align-items:center;background-color:", ";border-radius:", ";border:1px solid ", ";display:flex;justify-content:center;min-width:3rem;text-align:center;padding:0 ", ";"], tokens.color['neutral-2'], tokens.radius.md, tokens.color['neutral-5'], tokens.space.xs);
const StyledInputGroup = styled.div.withConfig({
  componentId: "sg1720__sc-y3tk1n-1"
})(["display:flex;"]);
const StyledAddon = styled.div.withConfig({
  componentId: "sg1720__sc-y3tk1n-2"
})(["display:flex;flex:0 0 auto;min-width:", ";", " ", "{", "}", " > ", ":not(:disabled){border-color:", ";}> ", "{border-radius:", ";border-width:1px;min-width:0;padding-left:", ";padding-right:", ";position:relative;", " &:focus{z-index:2;}}> ", " + ", ",> ", " + ", ",> ", " + ", ",> ", " + ", "{margin-left:-1px;}"], tokens.space.xxxl, props => props.$type === 'prepend' ? css(["margin-right:-1px;"]) : css(["margin-left:-1px;"]), StyledInputGroupText, props => props.$type === 'prepend' ? css(["border-bottom-right-radius:0;border-top-right-radius:0;"]) : css(["border-bottom-left-radius:0;border-top-left-radius:0;"]), props => !props.$disabled && css(["> ", ":disabled{border-color:", ";}"], /* sc-selector */StyledButton, tokens.color['neutral-7']), /* sc-selector */StyledButton, tokens.color['neutral-7'], /* sc-selector */StyledButton, tokens.radius.md, tokens.space.sm, tokens.space.sm, props => props.$type === 'prepend' ? css(["border-bottom-right-radius:0;border-top-right-radius:0;"]) : css(["border-bottom-left-radius:0;border-top-left-radius:0;&:not(:last-of-type){border-bottom-right-radius:0;border-top-right-radius:0;}"]), /* sc-selector */StyledButton, /* sc-selector */StyledButton, /* sc-selector */StyledButton, /* sc-selector */StyledInputGroupText, /* sc-selector */StyledInputGroupText, /* sc-selector */StyledInputGroupText, /* sc-selector */StyledInputGroupText, /* sc-selector */StyledButton);
const StyledFormElement = styled.div.withConfig({
  componentId: "sg1720__sc-y3tk1n-3"
})(["display:flex;flex:1 1 auto;flex-direction:column;z-index:1;min-width:0;"]);

export { StyledAddon, StyledFormElement, StyledInputGroup, StyledInputGroupText };

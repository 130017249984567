import React, { useState } from 'react'

import {
  BulletFillIcon,
  FeedATMTCard,
  Link,
  Text,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTCardExample = () => {
  const [cardExpanded, setCardExpanded] = useState(false)

  return (
    <FeedATMTCard
      description={{
        body: 'Mahdolliset lisätiedot. Avattavissa / piilotettavissa painikkeella.',
        summary: 'Aina näkyvissä oleva tiivis kuvaus.'
      }}
      details={{
        status: {
          icon: (
            <BulletFillIcon
              color={'success'}
              size={'md'}
            />
          ),
          text: <Text color={'success'}>Asian tila</Text>
        },
        textRows: [
          {
            label: 'Liittyvä päivämäärä',
            content: (
              <Time
                date={new Date('2024-1-1')}
                withYear
              />
            )
          }
        ]
      }}
      headingComponent={
        <Link
          as={LinkMock}
          to={'#'}
        >
          Asian otsikko
        </Link>
      }
      headingLevel={2}
      isExpanded={cardExpanded}
      toggleExpanded={() => setCardExpanded(!cardExpanded)}
    />
  )
}

export { FeedATMTCardExample }

import React, { useState } from 'react'

import {
  Button,
  Buttons,
  Dropdown,
  DropdownSection,
  Header,
  HeaderBackground,
  HeaderButton,
  HeaderSearch,
  SpeechBubbleFillIcon,
  TePalvelutFillIcon,
  TyomarkkinatoriFiWhiteSvg,
  useBreakpoint,
  UserIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

import { PlainSelectExample1 } from './PlainSelectExample1'

const HeaderTMTExample = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const isLg = useBreakpoint('lg')

  const [hasUser, setHasUser] = useState(false)

  const toggleLogin = () => setHasUser(!hasUser)

  const hasUserContent = (
    <Dropdown
      placement="bottom-end"
      trigger={
        <HeaderButton
          icon={<UserIcon />}
          subLabel={'Matin Nakkikioski'}
        >
          Matti
        </HeaderButton>
      }
    >
      <DropdownSection>
        <Buttons
          align="center"
          noMargin
        >
          <Button onClick={toggleLogin}>Kirjaudu ulos</Button>
        </Buttons>
      </DropdownSection>
    </Dropdown>
  )

  const noUserContent = (
    <HeaderButton
      icon={<UserIcon />}
      onClick={toggleLogin}
    >
      {isLg ? 'Kirjaudu sisään' : 'Kirjaudu'}
    </HeaderButton>
  )

  return (
    <HeaderBackground>
      <Header
        languageSelect={PlainSelectExample1()}
        isNavOpen={isNavOpen}
        onNavToggle={() => setIsNavOpen(!isNavOpen)}
        logo={
          <LinkMock>
            <TyomarkkinatoriFiWhiteSvg ariaLabel="Siirry etusivulle" />
          </LinkMock>
        }
        menu={[
          {
            component: <LinkMock to={'#henkiloasiakkaat'} />,
            label: 'Henkilöasiakkaat',
            active: true,
            children: [
              {
                component: <LinkMock to={'#tyonhaku'} />,
                label: 'Työnhaku',
                active: false
              },
              {
                component: <LinkMock to={'#koulutusjaura'} />,
                label: 'Koulutus ja ura',
                active: false
              },
              {
                component: <LinkMock to={'#eritavattyollistya'} />,
                label: 'Eri tavat työllistyä',
                active: false
              },
              {
                component: <LinkMock to={'#yrityksenperustaminen'} />,
                label: 'Yrityksen perustaminen',
                active: false
              },
              {
                component: <LinkMock to={'#ammattitieto'} />,
                label: 'Ammattitieto',
                active: true
              },
              {
                component: <LinkMock to={'#tyottomyys'} />,
                label: 'Työttömyys',
                active: false
              }
            ]
          },
          {
            component: <LinkMock to={'#tyonantajatjayrittajat'} />,
            label: 'Työnantajat ja yrittäjät',
            active: false
          },
          {
            component: <LinkMock to={'#yhteistyokumppanit'} />,
            label: 'Yhteistyökumppanit',
            active: false
          },
          {
            component: <LinkMock to={'#omatsivut'} />,
            label: 'Omat sivut',
            active: false,
            divider: true,
            icon: <SpeechBubbleFillIcon color={isLg ? 'white' : 'primary'} />,
            children: [
              {
                component: <LinkMock to={'#omatyonhaku'} />,
                label: 'Oma työnhaku',
                active: false
              },
              {
                component: <LinkMock to={'#tyonhakuprofiili'} />,
                label: 'Työnhakuprofiili',
                active: false
              },
              {
                component: <LinkMock to={'#selaatyopaikkoja'} />,
                label: 'Selaa työpaikkoja',
                active: false
              },
              {
                component: <LinkMock to={'#suositelluttyopaikat'} />,
                label: 'Suositellut työpaikat',
                active: false
              },
              {
                component: <LinkMock to={'#tyollistakotitaloutena'} />,
                label: 'Työllistä kotitaloutena',
                active: false
              }
            ]
          },
          {
            component: <LinkMock to={'#asiointi'} />,
            label: 'Asiointi',
            active: false,
            icon: <TePalvelutFillIcon color={'brand'} />
          }
        ]}
        search={
          <HeaderSearch
            onSubmit={() => console.log('HeaderSearch: onSubmit')}
          />
        }
        user={hasUser ? hasUserContent : noUserContent}
      />
    </HeaderBackground>
  )
}

export { HeaderTMTExample }

import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Button } from '../Button/Button.js';

const StyledClearButton = styled(Button).withConfig({
  componentId: "sg1720__sc-dzegde-0"
})(["position:absolute;right:", ";top:50%;transform:translateY(-50%);"], tokens.space.sm);
const StyledSpinnerWrapper = styled.div.withConfig({
  componentId: "sg1720__sc-dzegde-1"
})(["position:absolute;right:", ";top:50%;transform:translateY(-50%);"], tokens.space.sm);
const StyledFormControl = styled.input.withConfig({
  componentId: "sg1720__sc-dzegde-2"
})(["background-clip:padding-box;background-color:", ";border:1px solid ", ";border-radius:", ";color:", ";display:block;font-size:", ";height:3rem;line-height:2;padding:0.375rem ", ";padding-right:", ";resize:", ";transition:box-shadow ", ";width:100%;&::-ms-clear{display:none;}&:-moz-focusring{color:transparent;text-shadow:0 0 0 ", ";}&:focus{background-color:", ";border-color:", ";box-shadow:", ";color:", ";outline:0;}&::placeholder{color:", ";opacity:1;}&:disabled,&[readonly]{border-color:", ";opacity:1;}&:disabled{background-color:", ";color:", ";}&[readonly]{background-color:transparent;border:0;padding-left:calc(", " + 1px);padding-right:calc(", " + 1px);}&:not(:disabled):hover{border-color:", ";}input[type='date']&,input[type='time']&,input[type='datetime-local']&,input[type='month']&{appearance:none;}textarea&{box-shadow:none;height:auto;line-height:", ";min-height:3rem;padding:", ";}&[aria-invalid='true']{border-color:", ";&:focus{border-color:", ";}&:not(:disabled):hover{border-color:", ";}}", " ", ""], tokens.color.white, tokens.color['neutral-7'], tokens.radius.md, tokens.color['neutral-9'], tokens['font-size'].md, tokens.space.sm, props => props.$clearable && tokens.space.xxl, props => props.$resize, tokens.transition.fast, tokens.color['neutral-9'], tokens.color.white, tokens.color.primary, tokens.shadow.focus, tokens.color['neutral-9'], tokens.color['neutral-7'], tokens.color['neutral-5'], tokens.color['neutral-2'], tokens.color['neutral-6'], tokens.space.sm, tokens.space.sm, tokens.color['primary-light'], tokens['line-height'].md, tokens.space.sm, tokens.color.danger, tokens.color['danger-dark'], tokens.color['danger-light'], props => props.$addonAppend && css(["border-bottom-right-radius:0;border-top-right-radius:0;z-index:1;"]), props => props.$addonPrepend && css(["border-bottom-left-radius:0;border-top-left-radius:0;z-index:1;"]));
const StyledFormControlContainer = styled.div.withConfig({
  componentId: "sg1720__sc-dzegde-3"
})(["position:relative;width:100%;"]);

export { StyledClearButton, StyledFormControl, StyledFormControlContainer, StyledSpinnerWrapper };

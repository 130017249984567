import React from 'react'

import {
  Heading,
  Help,
  List,
  ListItem,
  Paragraph,
  TabList,
  TabListItem,
  TabPanel
} from '@te-digi/styleguide'

import { CodeBox } from '../../components/CodeBox'

import { Link as GatsbyLink } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { TabListExample } from '../../examples/react/TabListExample'
import { TabListItemStatusExample } from '../../examples/react/TabListItemStatusExample'
import { TabListSecondaryExample } from '../../examples/react/TabListSecondaryExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="TabList"
    components={[
      { component: TabList, restElement: true },
      { component: TabListItem, restElement: true },
      { component: TabPanel, restElement: true }
    ]}
  >
    <Section>
      <Paragraph>
        Käytetään jaottelemaan monimutkaisia sisältökokonaisuuksia pienemmiksi
        asiakokonaisuuksiksi.
      </Paragraph>
    </Section>

    <Help>
      <Heading
        level={2}
        size={4}
      >
        Tabs ja TabList eroavaisuudet
      </Heading>
      <Paragraph>
        Tabeja suunnitellessa ja toteuttaessa on huomioitava, että Style
        Guidessa on kaksi samalta näyttävää, mutta semanttisesti toisistaan
        eroavaa välilehtikomponenttia. Ruudunlukija ilmoittaa semanttisen
        merkkauksen käyttäjälle, mikä helpottaa sivun hahmottamista.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Code>
            <GatsbyLink page="Tabs" />
          </Code>{' '}
          tuottaa html-elementin <Code>nav</Code>, jonka semanttinen rooli on{' '}
          <Code>navigation</Code>. Rooli ilmaisee, että kyseessä on
          navigaatioelementti, ja linkin valinta johtaa uudelle sivulle.
          React-komponenttina ei suositeltu.
        </ListItem>
        <ListItem>
          <Code>TabList</Code> tuottaa elementin, jonka semanttinen rooli on{' '}
          <Code>role=tablist</Code>. Se tarkoittaa, että vaihdetaan välilehteä
          saman sivun sisällä. Suositeltava vaihtoehto useimmissa tapauksissa.
        </ListItem>
      </List>

      <Paragraph noMargin>
        Lue lisää <a href="#saavutettavuus">Saavutettavuus</a>-osiosta.
      </Paragraph>
    </Help>

    <Section title="Semanttinen välilehtirakenne">
      <Paragraph>
        Semanttista välilehtirakennetta varten tarvitaan kolme rakennuspalikkaa:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Code>TabList</Code>
        </ListItem>
        <ListItem>
          <Code>TabListItem</Code>
        </ListItem>
        <ListItem>
          <Code>TabPanel</Code>
        </ListItem>
      </List>

      <Paragraph>
        Välilehtien lista (<Code>TabList</Code>) sisältää painikkeet välilehtien
        avaamista varten (<Code>TabListItem</Code>). Näiden jälkeen sijoitetaan
        paneelit välilehtien sisällölle (<Code>TabPanel</Code>). Kukin{' '}
        <Code>TabListItem</Code> liittyy yhteen <Code>TabPanel</Code>iin.
      </Paragraph>

      <Paragraph>
        <Code>TabListItem</Code> ja siihen liittyvä <Code>TabPanel</Code>{' '}
        linkataan yhteen <Code>tabIdentifier</Code>illä. Esim. jos yhden
        välilehden nimi on Tilanne, se linkataan Tilanne-paneliin. On tärkeää
        huolehtia, että <Code>tabIdentifier</Code> on uniikki eri{' '}
        <Code>TabListItem</Code>
        ien välillä. Samoin jos samalla sivulla on useampia <Code>TabList</Code>
        eja, kaikkien <Code>tabIdentifier</Code>eiden tulee olla uniikkeja.
      </Paragraph>
      <Paragraph>
        Allaolevassa pseudokoodissa on kuvattu komponenttien käyttöä yhdessä
        React Routerin kanssa.
      </Paragraph>

      <CodeBox>
        {'const uniqueId = ...\n' +
          '\n' +
          '<TabList ... \n' +
          '  items={pages.map((page) => (\n' +
          '    <TabListItem ... \n' +
          '      tabIdentifier={uniqueId + page.name}\n' +
          '      >...</TabListItem>\n' +
          '  ))}\n' +
          '/>\n' +
          '\n' +
          '<Routes>\n' +
          '  {pages.map((page) => {\n' +
          '    const {RouteComp} = page\n' +
          '    return (\n' +
          '      <Route\n' +
          '        key={page.name}\n' +
          '        path={page.path}\n' +
          '        element={\n' +
          '          <TabPanel\n' +
          '            tabIdentifier={uniqueId + page.name}\n' +
          '          >\n' +
          '            <RouteComp />\n' +
          '          </TabPanel>\n' +
          '        }\n' +
          '      />\n' +
          '    )\n' +
          '  })}\n' +
          '</Routes>\n'}
      </CodeBox>

      <Playground example={TabListExample} />
    </Section>
    <Section title="Välilehden sisäiset välilehdet">
      <Paragraph>
        Kun sivulla on jo ensisijaiset välilehdet, on mahdollista käyttää
        visuaalisesti erovia toissijaisia välilehtiä.
      </Paragraph>
      <Playground example={TabListSecondaryExample} />
    </Section>
    <Section title="Välilehden lisäominaisuudet">
      <List variant="unordered">
        <ListItem>Huomioitavaa</ListItem>
        <List variant="unordered">
          <ListItem>
            Jos välilehdellä on käyttäjälle jotain huomioitavaa, välilehdellä
            voi näyttää huomio-merkin (<Code>AlertCircleIcon</Code>, muista
            lisätä ikonille myös <Code>ariaLabel</Code>-proppi, esim. teksti
            &quot;Huomioitavaa&quot;)
          </ListItem>
          <ListItem>
            <Code>TabItem</Code>in väriksi annetaan <Code>color</Code>-propilla{' '}
            <Code>secondary</Code>
          </ListItem>
        </List>
        <ListItem>Varoitus</ListItem>
        <List variant="unordered">
          <ListItem>
            Jos välilehti sisältää lomakekenttiä, joissa on virhe, välilehdellä
            on näytettävä virhe-merkki (<Code>ErrorIcon</Code>, muista lisätä
            ikonille <Code>ariaLabel</Code>-proppi, esim. teksti
            &quot;Virheitä&quot;)
          </ListItem>
          <ListItem>
            <Code>TabItem</Code>in väriksi annetaan <Code>color</Code>-propilla{' '}
            <Code>danger</Code>
          </ListItem>
        </List>
        <ListItem>
          Välilehti on myös mahdollista merkitä disabloiduksi{' '}
          <Code>disabled</Code> -propilla
        </ListItem>
      </List>
      <Playground example={TabListItemStatusExample} />
    </Section>
    <Section title="Saavutettavuus">
      <List variant="unordered">
        <ListItem>
          <Code>TabList</Code> tulee linkittää <Code>ariaLabelledby</Code>
          -propilla sen yläpuolella olevaan otsikkoon tai muuhun yhdistävään
          tekijään.
        </ListItem>
        <ListItem>
          Välilehden huomio-merkinnän tulee sisältää saavutettava teksti
          ikonille.
        </ListItem>
      </List>
      <SectionHeading>Tabsin ja TabListin ero</SectionHeading>
      <Paragraph>
        <Code>TabList</Code> on aito välilehtirakenne, jonka voidaan ajatella
        pilkkovan pitkän, väliotsikoilla varustetun sisällön pienempiin osiin
        eli välilehtiin. Valinta <Code>TabList</Code>ista tarkoittaa siirtymää
        sivun sisällä, eli asiakokonaisuus ei vaihdu.
      </Paragraph>
      <Paragraph>
        <Code>Tabs</Code> on teknisesti navigaatio, jonka linkit näyttävät
        välilehdiltä. Navigaation linkit ohjaavat toiselle sivulle, mikä
        tarkoittaa myös fokuksen siirtymistä sivun alkuun. Tyypillisesti tällöin
        uusi sivu on käyttäjän kannalta uusi asia tai kokonaisuus.
      </Paragraph>
      <Paragraph>
        Näkevälle käyttäjälle komponenttien ero näkyy lähinnä siinä, että{' '}
        <Code>TabList</Code>llä välilehteä vaihtaessa sivun kohdistus pysyy
        valitussa välilehdessä, kun taas <Code>Tabs</Code>-komponentilla
        välilehteä vaihtaessa kohdistus hyppäää sivun alkuun (tämän huomaa
        lähinnä pitkillä sivuilla, joissa <Code>Tabs</Code> on hieman alempana
        sivulla). Ruudunlukijalla navigoivalle ero on kuitenkin merkittävämpi:
        kun käyttäjä tietää, että kyseessä on linkki, hän ymmärtää siirtyvänsä
        uudelle sivulle. Kun kyseessä on tablist, hän tietää välilehden
        vaihtuvan ja fokuksen säilyvän sivun keskellä. Tällä on merkitystä, kun
        käyttäjä hahmottaa mielessään sivuston ja sivun rakennetta.
      </Paragraph>
      <Paragraph>
        Komponentit käyttäytyvät eri tavalla myös näppäimistöllä navigoidessa:{' '}
        <Code>Tabs</Code>issa linkkien välillä navigoidaan tab-näppäimellä, kun{' '}
        <Code>TabList</Code>issä navigointi tapahtuu nuolinäppäimillä ja
        tab-näppäimen painallus siirtää fokuksen eteenpäin seuraavaan
        fokusoitavaan elementtiin.
      </Paragraph>
    </Section>
  </Content>
)

export default Page

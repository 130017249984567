import styled from 'styled-components';

const StyledInputWrapper = styled.div.withConfig({
  componentId: "sg1720__sc-2wd31k-0"
})(["display:inline-flex;position:relative;"]);
const StyledBaseButtonInput = styled.input.withConfig({
  componentId: "sg1720__sc-2wd31k-1"
})(["height:100%;opacity:0;position:absolute;z-index:-1;width:100%;"]);
const StyledButtonSpinnerContainer = styled.span.withConfig({
  componentId: "sg1720__sc-2wd31k-2"
})(["align-items:center;bottom:0;display:flex;justify-content:center;left:0;position:absolute;right:0;top:0;"]);

export { StyledBaseButtonInput, StyledButtonSpinnerContainer, StyledInputWrapper };

const translations = {
  en: {
    add: 'Add',
    addDescription: 'Add description',
    bold: 'Bold',
    broadDescription: 'Broad description',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    closeMenu: 'Close menu',
    collapse: 'Collapse',
    date: 'Date',
    dateFormat: 'dd.mm.yyyy',
    dateRangeEnd: 'Ending',
    dateRangeStart: 'Starting on',
    day: 'Day',
    deleted: 'removed',
    descriptionForAttachment: 'Description for attachment',
    edit: 'Edit',
    errorsOrMissingInfo: 'Incorrect or missing information',
    eventNone: 'no events',
    eventOne: 'event',
    eventMany: 'events',
    expand: 'Expand',
    externalLink: 'Opens in a new tab',
    feedback: 'Feedback',
    hideEvents: 'Hide events',
    hours: 'Hours',
    incomplete: 'incomplete',
    invalidCharsError: 'Invalid characters',
    italic: 'Italic',
    loading: 'Loading',
    loadingOptions: 'Loading items...',
    longPasteError: 'The text is too long. Only part of the text was pasted.',
    longPasteErrorSafari: 'The text is too long to paste.',
    mainNavigation: 'Main navigation',
    menu: 'Menu',
    minutes: 'Minutes',
    missing: 'Missing',
    month: 'Month',
    moreInformation: 'More information',
    next: 'Next',
    nextMonth: 'Next Month',
    nextYear: 'Next Year',
    notFoundPageBody: 'We are sorry. The page you tried to enter could not be found for one reason or another.',
    notFoundPageHeading: "Oh no! We couldn't find the page you are looking for.",
    notFoundPageLink: 'We hope you find what you are looking for on',
    openCalendar: 'Open the calendar',
    openMenu: 'Open menu',
    openTimePicker: 'Select time',
    orderedList: 'Ordered List',
    previous: 'Previous',
    previousMonth: 'Previous Month',
    previousYear: 'Previous Year',
    ready: 'Filled',
    saveStatusSaved: 'All information saved',
    saveStatusSavedAt: 'Saved',
    saveStatusSaving: 'Saving…',
    search: 'Search',
    select: 'Select',
    selectDateRange: 'Select a time interval',
    selectSearchableEmpty: 'No options were found.',
    selectSearchableFoundA11y: 'options found.',
    selected: 'Selected',
    showMoreEvents: 'Show more events',
    sideNavSubitems: 'Sublevel navigation',
    siteNotificationsMore: 'Disruption notifications',
    skipToContent: 'Skip to content',
    subSteps: 'Sublevel steps',
    subtract: 'Subtract',
    time: 'Time',
    typeOrSelect: 'Type or select',
    unorderedList: 'Unordered List',
    year: 'Year'
  },
  fi: {
    add: 'Lisää',
    addDescription: 'Lisää kuvaus',
    bold: 'Lihavointi',
    broadDescription: 'Laaja kuvaus',
    cancel: 'Peruuta',
    clear: 'Tyhjennä',
    close: 'Sulje',
    closeMenu: 'Sulje valikko',
    collapse: 'Tiivistä',
    date: 'Päivämäärä',
    dateFormat: 'pp.kk.vvvv',
    dateRangeEnd: 'Päättyen',
    dateRangeStart: 'Alkaen',
    day: 'Päivä',
    deleted: 'poistettu',
    descriptionForAttachment: 'Kuvaus liitteelle',
    edit: 'Muokkaa',
    errorsOrMissingInfo: 'Virheellisiä tai puuttuvia tietoja',
    eventNone: 'ei tapahtumia',
    eventOne: 'tapahtuma',
    eventMany: 'tapahtumaa',
    expand: 'Laajenna',
    externalLink: 'Avautuu uuteen välilehteen',
    feedback: 'Palaute',
    hideEvents: 'Piilota tapahtumat',
    hours: 'Tunnit',
    incomplete: 'puutteita',
    invalidCharsError: 'Virheellisiä merkkejä',
    italic: 'Kursivointi',
    loading: 'Ladataan',
    loadingOptions: 'Vaihtoehtoja ladataan...',
    longPasteError: 'Vain osa tekstistä liitettiin.',
    longPasteErrorSafari: 'Teksti on liian pitkä liitettäväksi.',
    mainNavigation: 'Päänavigaatio',
    menu: 'Valikko',
    minutes: 'Minuutit',
    missing: 'Puuttuva',
    month: 'Kuukausi',
    moreInformation: 'Lisätietoa',
    next: 'Seuraava',
    nextMonth: 'Seuraava kuukausi',
    nextYear: 'Seuraava vuosi',
    notFoundPageBody: 'Olemme pahoillamme. Sivua, johon yritit päästä, ei syystä tai toisesta löytynyt.',
    notFoundPageHeading: 'Voi ei! Etsimääsi sivua ei löytynyt.',
    notFoundPageLink: 'Toivottavasti löydät etsimäsi',
    openCalendar: 'Avaa kalenteri',
    openMenu: 'Avaa valikko',
    openTimePicker: 'Valitse aika',
    orderedList: 'Numerointi',
    previous: 'Edellinen',
    previousMonth: 'Edellinen kuukausi',
    previousYear: 'Edellinen vuosi',
    ready: 'Täytetty',
    saveStatusSaved: 'Kaikki tiedot on tallennettu',
    saveStatusSavedAt: 'Tallennettu',
    saveStatusSaving: 'Tallennetaan…',
    search: 'Hae',
    select: 'Valitse',
    selectDateRange: 'Valitse aikajakso',
    selectSearchableEmpty: 'Vaihtoehtoja ei löytynyt.',
    selectSearchableFoundA11y: 'vaihtoehtoa löydetty.',
    selected: 'Valittu',
    showMoreEvents: 'Näytä lisää tapahtumia',
    sideNavSubitems: 'Alatason navigaatio',
    siteNotificationsMore: 'Häiriötiedotteet',
    skipToContent: 'Hyppää sisältöön',
    subSteps: 'Alatason vaiheet',
    subtract: 'Vähennä',
    time: 'Aika',
    typeOrSelect: 'Kirjoita tai valitse',
    unorderedList: 'Luettelomerkit',
    year: 'Vuosi'
  },
  sv: {
    add: 'Lägg till',
    addDescription: 'Lägg till en beskrivning',
    bold: 'Fet',
    broadDescription: 'Bred beskrivning',
    cancel: 'Ångra',
    clear: 'Töm',
    close: 'Stäng',
    closeMenu: 'Stäng menyn',
    collapse: 'Dölja',
    date: 'Datum',
    dateFormat: 'dd.mm.åååå',
    dateRangeEnd: 'Slut',
    dateRangeStart: 'Från och med',
    day: 'Dag',
    deleted: 'raderade',
    descriptionForAttachment: 'Beskrivning av bilaga',
    edit: 'Redigera',
    errorsOrMissingInfo: 'Felaktig eller saknad information.',
    eventNone: 'inga händelser',
    eventOne: 'händelse',
    eventMany: 'händelser',
    expand: 'Visa',
    externalLink: 'Öppnas i en ny flik',
    feedback: 'Respons',
    hideEvents: 'Dölj händelser',
    hours: 'Timmar',
    incomplete: 'bristande',
    invalidCharsError: 'Ogiltiga tecken',
    italic: 'Kursiv',
    loading: 'Laddar',
    loadingOptions: 'Alternativ laddas...',
    longPasteError: 'Texten är för lång. Endast en del av texten bifogades.',
    longPasteErrorSafari: 'Texten är för lång.',
    mainNavigation: 'Huvudnavigering',
    menu: 'Meny',
    minutes: 'Minuter',
    missing: 'Saknas',
    month: 'Månad',
    moreInformation: 'Mer information',
    next: 'Nästa',
    nextMonth: 'Nästa månad',
    nextYear: 'Nästa år',
    notFoundPageBody: 'Vi beklagar. Sidan du försökte nå, hittades inte av någon anledning.',
    notFoundPageHeading: 'Voj nej! Sidan du sökte hittades inte.',
    notFoundPageLink: 'Hoppas du hittar det du söker på',
    openCalendar: 'Öppna kalendern',
    openMenu: 'Öppna meny',
    openTimePicker: 'Välj tid',
    orderedList: 'Nummerlista',
    previous: 'Föregående',
    previousMonth: 'Föregående månad',
    previousYear: 'Föregående år',
    ready: 'Fylld',
    saveStatusSaved: 'Alla uppgifter sparade',
    saveStatusSavedAt: 'Sparad',
    saveStatusSaving: 'Sparar…',
    search: 'Sök',
    select: 'Välj',
    selectDateRange: 'Välj ett tidsintervall',
    selectSearchableEmpty: 'Inga alternativ hittades.',
    selectSearchableFoundA11y: 'altervativ hittade.',
    selected: 'Vald',
    showMoreEvents: 'Visa mer händelser',
    sideNavSubitems: 'Navigering på undernivå',
    siteNotificationsMore: 'Störningsmeddelanden',
    skipToContent: 'Hoppa till innehållet',
    subSteps: 'Steg på undernivå',
    subtract: 'Subtrahera',
    time: 'Tid',
    typeOrSelect: 'Skriv eller välj',
    unorderedList: 'Punktlista',
    year: 'År'
  }
};

export { translations };

import React, { useState } from 'react'
import {
  ArrowRightIcon,
  Button,
  CheckIcon,
  Divider,
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  HeaderATMT,
  HeaderBackground,
  Inline,
  Lead,
  LockIcon,
  Paragraph,
  QuestionCircleIcon,
  SkipLink,
  SortIcon,
  Spacer,
  TyomarkkinatoriAsiantuntijaSmallFiWhiteSvg,
  useBreakpoint,
  UserIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const HeaderATMTWithManyOrganizationsExample = () => {
  const isLg = useBreakpoint('lg')

  const [activeItem, setActiveItem] = useState('Henkilöasiakkaat')
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [hasUser, setHasUser] = useState(false)
  const [activeLang, setActiveLang] = useState<'fi' | 'sv' | 'en'>('fi')

  const toggleLogin = () => setHasUser(!hasUser)

  const hasUserContent = (
    <Dropdown
      placement="bottom-end"
      trigger={
        <Button
          color={'white'}
          iconLeft={<UserIcon />}
          variant={'plain'}
        >
          <Inline>
            Veli Viranomainen <Divider variant={'inlineVertical'} /> Uudenmaan
            työ- ja elinkeinotoimisto
          </Inline>
        </Button>
      }
    >
      <DropdownSection>
        <Lead noMargin>Veli Viranomainen</Lead>
        <Paragraph>Uudenmaan työ- ja elinkeinotoimisto</Paragraph>
        <Button
          variant={'plain'}
          iconLeft={<SortIcon />}
          onClick={toggleLogin}
        >
          Vaihda organisaatiota
        </Button>
      </DropdownSection>
      <DropdownDivider />
      <DropdownSection>
        <Button
          variant={'plain'}
          iconLeft={<LockIcon />}
          onClick={toggleLogin}
        >
          Kirjaudu ulos
        </Button>
      </DropdownSection>
    </Dropdown>
  )

  const noUserContent = (
    <Button
      color={'white'}
      iconLeft={<UserIcon />}
      onClick={toggleLogin}
      variant={'plain'}
    >
      Kirjaudu sisään
    </Button>
  )

  const instructionsComponent = (
    <Button
      as={LinkMock}
      color={isLg ? 'white' : 'primary'}
      iconLeft={<QuestionCircleIcon />}
      variant={'plain'}
    >
      Ohjeet ja tuki
    </Button>
  )

  const languageSelectComponent = (
    <Dropdown
      trigger={
        <Button
          color={'white'}
          variant={'plain'}
        >
          Suomi
        </Button>
      }
    >
      <DropdownSection disablePaddingBottom={true}>
        <Lead noMargin>Vaihda kieltä</Lead>
      </DropdownSection>
      <DropdownActions>
        <DropdownActionsItem
          as={'button'}
          icon={
            activeLang === 'fi' ? <CheckIcon /> : <Spacer paddingRight={'lg'} />
          }
          onClick={() => setActiveLang('fi')}
        >
          Suomi
        </DropdownActionsItem>
        <DropdownActionsItem
          as={'button'}
          icon={
            activeLang === 'sv' ? <CheckIcon /> : <Spacer paddingRight={'lg'} />
          }
          onClick={() => setActiveLang('sv')}
        >
          Svenska
        </DropdownActionsItem>
        <DropdownActionsItem
          as={'button'}
          icon={
            activeLang === 'en' ? <CheckIcon /> : <Spacer paddingRight={'lg'} />
          }
          onClick={() => setActiveLang('en')}
        >
          English
        </DropdownActionsItem>
      </DropdownActions>
      <DropdownDivider />
      <DropdownSection disablePaddingBottom={true}>
        <Lead noMargin>Tietoa muilla kielillä</Lead>
      </DropdownSection>
      <DropdownActions>
        <DropdownActionsItem
          as={LinkMock}
          iconRight={<ArrowRightIcon />}
          to="#"
        >
          Selkosuomi
        </DropdownActionsItem>
        <DropdownActionsItem
          as={LinkMock}
          iconRight={<ArrowRightIcon />}
          to="#"
        >
          Saame
        </DropdownActionsItem>
      </DropdownActions>
    </Dropdown>
  )

  return (
    <HeaderBackground>
      <HeaderATMT
        skipLink={<SkipLink href={'#'} />}
        isNavOpen={isNavOpen}
        languageSelect={languageSelectComponent}
        logo={
          <LinkMock>
            <TyomarkkinatoriAsiantuntijaSmallFiWhiteSvg ariaLabel="Siirry etusivulle" />
          </LinkMock>
        }
        instructions={instructionsComponent}
        menu={[
          {
            component: (
              <LinkMock
                to={'#tyotehtavat'}
                onClick={() => setActiveItem('Työtehtävät')}
              />
            ),
            label: 'Työtehtävät',
            active: activeItem === 'Työtehtävät'
          },
          {
            component: (
              <LinkMock
                to={'#henkiloasiakkaat'}
                onClick={() => setActiveItem('Henkilöasiakkaat')}
              />
            ),
            label: 'Henkilöasiakkaat',
            active: activeItem === 'Henkilöasiakkaat'
          },
          {
            component: (
              <LinkMock
                to={'#tyonsntajatjayrittajat'}
                onClick={() => setActiveItem('Työnantajat ja yrittäjät')}
              />
            ),
            label: 'Työnantajat ja yrittäjät',
            active: activeItem === 'Työnantajat ja yrittäjät'
          },
          {
            component: (
              <LinkMock
                to={'#palvelut'}
                onClick={() => setActiveItem('Palvelut')}
              />
            ),
            label: 'Palvelut',
            active: activeItem === 'Palvelut'
          }
        ]}
        onNavToggle={() => setIsNavOpen(!isNavOpen)}
        user={hasUser ? hasUserContent : noUserContent}
      />
    </HeaderBackground>
  )
}

export { HeaderATMTWithManyOrganizationsExample }

import React, { ReactNode } from 'react'
import tokens from '@te-digi/styleguide-tokens'

import {
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const TableForContent = ({ children }: { children?: ReactNode }) => (
  <Table divider="horizontal">
    <TableHeader>
      <TableRow>
        <TableHeaderCell style={{ width: '25%' }}>Ominaisuus</TableHeaderCell>
        <TableHeaderCell>CSS-luokat</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>{children}</TableBody>
  </Table>
)

const Row = ({ row }: { row: { title: string; items: ReactNode[] } }) => (
  <TableRow>
    <TableCell>
      <Text fontWeight="semi-bold">{row.title}</Text>
    </TableCell>
    <TableCell>
      <List variant="unordered">
        {row.items.map((item, i) => (
          <ListItem key={i}>{item}</ListItem>
        ))}
      </List>
    </TableCell>
  </TableRow>
)

const Page = () => (
  <Content
    heading="CSS-luokat"
    lead="Verkkopalvelulle tarkoitetut globaalit CSS-luokat."
  >
    <Section title="Background">
      <TableForContent>
        {[
          {
            title: 'Color',
            items: [
              <Code>.bg-primary</Code>,
              <Code>.bg-light</Code>,
              <Code>.bg-white</Code>,
              <Code>.bg-transparent</Code>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Border">
      <TableForContent>
        {[
          {
            title: 'Border',
            items: [<Code>.border-0</Code>, <Code>.border-dashed</Code>]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Display">
      <TableForContent>
        {[
          {
            title: 'Display',
            items: [
              <Code>
                .d-
                <Text color="dark">
                  [none | inline | inline-block | block | table | table-row |
                  table-cell | flex | inline-flex]
                </Text>
              </Code>,
              <>
                <Code>
                  .d-
                  <Text color="dark">[breakpoint]</Text>
                  -…
                </Code>
                <br />
                Display-tyyppi valitulle näytönleveydelle (sm, md, lg, xl)
              </>,
              <>
                <Code>.d-print-…</Code>
                <br />
                Display-tyyppi tulosteessa
              </>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Flex">
      <TableForContent>
        {[
          {
            title: 'Display',
            items: [<Code>.flex</Code>, <Code>.inline-flex</Code>]
          },
          { title: 'Direction', items: [<Code>.flex-column</Code>] },
          {
            title: 'Align Items',
            items: [
              <Code>.align-items-center</Code>,
              <Code>.align-items-start</Code>,
              <Code>.align-items-end</Code>
            ]
          },
          {
            title: 'Justify Content',
            items: [
              <Code>.justify-content-start</Code>,
              <Code>.justify-content-center</Code>,
              <Code>.justify-content-end</Code>,
              <Code>.justify-content-around</Code>,
              <Code>.justify-content-between</Code>
            ]
          },
          { title: 'Fill', items: [<Code>.flex-fill</Code>] },
          {
            title: 'Wrap',
            items: [<Code>.flex-nowrap</Code>, <Code>.flex-wrap</Code>]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Radius">
      <TableForContent>
        {[
          {
            title: 'Radius',
            items: [
              <Code>.radius-0</Code>,
              <Code>.radius-md</Code>,
              <Code>.radius-lg</Code>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Resize">
      <TableForContent>
        {[
          {
            title: 'Resize',
            items: [<Code>.resize-none</Code>]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Shadow">
      <TableForContent>
        {[
          {
            title: 'Shadow',
            items: [
              <Code>.shadow-card</Code>,
              <Code>.shadow-default</Code>,
              <Code>.shadow-dark</Code>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Space">
      <TableForContent>
        {[
          {
            title: 'Margin',
            items: [
              <>
                <Code>
                  .m<Text color="dark">[t | r | b | l]</Text>-…
                </Code>
                <br />
                Marginaali kaikille tai valitulle sivulle (top, right, bottom,
                left)
              </>,
              <>
                <Code>
                  .m-<Text color="dark">[0 | auto | koko]</Text>
                </Code>
                <br />
                Marginaalin koko
              </>,
              <>
                <Code>
                  .m-<Text color="dark">[breakpoint]</Text>-
                  <Text color="dark">[0 | auto | koko]</Text>
                </Code>
                <br />
                Marginaali valitulle näytönleveydelle (sm, md, lg, xl)
              </>,
              <>
                <Code>
                  .m<Text color="dark">[t | r | b | l]</Text>-n-
                  <Text color="dark">[koko]</Text>
                </Code>
                <br />
                Negatiivinen marginaali valitulle sivulle
              </>,
              <>
                <Code>
                  .m<Text color="dark">[t | r | b | l]</Text>-
                  <Text color="dark">[breakpoint]</Text>-n-
                  <Text color="dark">[koko]</Text>
                </Code>
                <br />
                Negatiivinen marginaali valitulle sivulle näytönleveyden mukaan
              </>
            ]
          },
          {
            title: 'Padding',
            items: [
              <>
                <Code>
                  .p<Text color="dark">[t | r | b | l]</Text>-…
                </Code>
                <br />
                Täytealue kaikille tai valitulle sivulle (top, right, bottom,
                left)
              </>,
              <>
                <Code>
                  .p-<Text color="dark">[0 | auto | koko]</Text>
                </Code>
                <br />
                Täytealue kaikille sivuille
              </>,
              <>
                <Code>
                  .p-<Text color="dark">[breakpoint]</Text>-
                  <Text color="dark">[0 | auto | koko]</Text>
                </Code>
                <br />
                Täytealue valitulle näytönleveydelle (sm, md, lg, xl)
              </>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
    <Section title="Typography">
      <TableForContent>
        {[
          {
            title: 'Typography',
            items: [<Code>.typography</Code>]
          },
          {
            title: 'Heading',
            items: [
              <Code>.heading-headline</Code>,
              <Code>.h1</Code>,
              <Code>.h2</Code>,
              <Code>.h3</Code>,
              <Code>.h4</Code>,
              <Code>.h5</Code>,
              <Code>.heading-overline</Code>,
              <Code>.heading-subheading</Code>
            ]
          },
          {
            title: 'Lead',
            items: [<Code>.lead</Code>]
          },
          {
            title: 'Text Align',
            items: [
              <Code>.text-center</Code>,
              <Code>.text-left</Code>,
              <Code>.text-right</Code>
            ]
          },
          {
            title: 'Color',
            items: [
              <Code>.text-primary</Code>,
              <Code>.text-secondary</Code>,
              <Code>.text-danger</Code>,
              <Code>.text-success</Code>,
              <Code>.text-warning</Code>,
              <Code>.text-neutral-1</Code>,
              <Code>.text-neutral-2</Code>,
              <Code>.text-neutral-3</Code>,
              <Code>.text-neutral-4</Code>,
              <Code>.text-neutral-5</Code>,
              <Code>.text-neutral-6</Code>,
              <>
                <Code>.text-neutral-7</Code> (<Code>.text-dark</Code>)
              </>,
              <Code>.text-neutral-8</Code>,
              <Code>.text-neutral-9</Code>,
              <Code>.text-neutral-10</Code>,
              <Code>.text-white</Code>
            ]
          },
          {
            title: 'Weight',
            items: [
              <Code>.text-weight-normal</Code>,
              <Code>.text-weight-semi-bold</Code>
            ]
          },
          {
            title: 'Line-Height',
            items: [
              <Code>.text-line-height-xs</Code>,
              <Code>.text-line-height-sm</Code>,
              <Code>.text-line-height-md</Code>
            ]
          },
          {
            title: 'Size',
            items: Object.keys(tokens['font-size']).map(size => (
              <Code key={size}>.text-{size}</Code>
            ))
          },
          {
            title: 'Style',
            items: [
              <Code>.text-style-normal</Code>,
              <Code>.text-style-italic</Code>
            ]
          },
          {
            title: 'Transform',
            items: [
              <Code>.text-transform-capitalize</Code>,
              <Code>.text-transform-uppercase</Code>,
              <Code>.text-transform-lowercase</Code>
            ]
          }
        ].map((row, i) => (
          <Row
            key={i}
            row={row}
          />
        ))}
      </TableForContent>
    </Section>
  </Content>
)

export default Page

import React, { useEffect } from 'react'

import { Paragraph, PlainSelect } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Link } from '../../components/Link'
import { PlainSelectExample1 } from '../../examples/react/PlainSelectExample1'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

export const PlainSelectHTMLExample = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializePlainSelect()
  }, [])

  return (
    <div className="plain-select">
      <select
        aria-label="Valitse kieli"
        className="plain-select-control"
      >
        <option>Suomi</option>
        <option>Ruotsi</option>
        <option>Englanti</option>
        <option>Selkosuomi</option>
        <option>Saame</option>
      </select>
      <IconHTMLExample
        className="plain-select-icon"
        name="ChevronDownIcon"
        size="lg"
      />
    </div>
  )
}

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="PlainSelect"
    components={[{ component: PlainSelect }]}
    status={[{ type: 'deprecated', version: '17.2.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>PlainSelect</Code>-komponenttia käytetään{' '}
        <Code>
          <Link page="Header" />
        </Code>
        -yläpalkissa sivuston käyttöliittymäkielen valintaan.
      </Paragraph>
      <Playground example={PlainSelectExample1} />
      <Playground format="html">{PlainSelectHTMLExample()}</Playground>
    </Section>
  </Content>
)

export default Page

import { useState, useEffect } from 'react';
import tokens from '@te-digi/styleguide-tokens';

function useBreakpoint(breakpoint) {
  let customPixels = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const hasMatchMedia = typeof window !== 'undefined' && 'matchMedia' in window;
  let query;
  if (breakpoint === 'custom') {
    query = "(min-width: ".concat(customPixels, "px)");
  } else {
    query = {
      sm: "(min-width: ".concat(tokens.breakpoint.sm, ")"),
      md: "(min-width: ".concat(tokens.breakpoint.md, ")"),
      lg: "(min-width: ".concat(tokens.breakpoint.lg, ")"),
      xl: "(min-width: ".concat(tokens.breakpoint.xl, ")")
    }[breakpoint];
  }
  const [state, setState] = useState(hasMatchMedia ? () => window.matchMedia(query).matches : false);
  useEffect(() => {
    if (!hasMatchMedia) {
      return;
    }
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(mql.matches);
    };
    mql.addEventListener('change', onChange);
    setState(mql.matches);
    return () => {
      mounted = false;
      mql.removeEventListener('change', onChange);
    };
  }, [hasMatchMedia, query]);
  return state;
}

export { useBreakpoint };

import React from 'react'
import { SearchIcon, XIcon } from '@te-digi/styleguide'

import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'

import IconHTMLExample from './IconHTMLExample'

const HeaderSearchHTMLExample = () => (
  <form
    action="https://tyomarkkinatori.fi/haku"
    className="page-header-search-form"
    method="get"
    role="search"
  >
    <div className="page-header-search-input-container">
      <input
        aria-label="Hae"
        className="form-control page-header-search-input"
        name="q"
        type="text"
        placeholder="Hae…"
        required
      />
      <ButtonHTMLExample
        className="page-header-search-submit"
        color="white"
        icon={<SearchIcon />}
        shape="circle"
        type="submit"
      >
        Hae
      </ButtonHTMLExample>
    </div>
  </form>
)

export const HeaderSearchAsiointiHTMLExample = () => (
  <div className="header-search-container">
    <form
      action="https://tyomarkkinatori.fi/haku"
      className="header-search-form"
      method="get"
      role="search"
    >
      <div className="input-group">
        <input
          aria-label="Hae"
          className="form-control form-control-append"
          id="header-search-input"
          name="q"
          type="text"
          placeholder="Hae Työmarkkinatorilta…"
          required
        />
        <div className="input-group-append">
          <ButtonHTMLExample
            className="btn btn-input-group pl-lg pr-lg"
            color="secondary"
            iconLeft={<SearchIcon />}
            type="submit"
          >
            Hae
          </ButtonHTMLExample>
        </div>
      </div>
    </form>
    <ButtonHTMLExample
      className="btn btn-primary btn-plain-white ml-md header-search-hide pl-md pr-md"
      aria-controls="search-bar-container"
      type="button"
      iconLeft={<XIcon />}
    >
      Piilota haku
    </ButtonHTMLExample>
  </div>
)

export const HeaderSearchMobileHTMLExample = () => (
  <form
    action="https://tyomarkkinatori.fi/haku"
    className="header-search-form"
    method="get"
    role="search"
  >
    <div className="input-group">
      <input
        className="form-control form-control-append"
        type="text"
        placeholder="Hae Työmarkkinatorilta…"
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-primary btn-input-group"
          type="button"
        >
          <span className="btn-content">
            <IconHTMLExample
              name="SearchIcon"
              className="icon icon-lg btn-icon"
            />
            <span className="visually-hidden">Hae</span>
          </span>
        </button>
      </div>
    </div>
  </form>
)

export default HeaderSearchHTMLExample

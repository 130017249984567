import React, { useState } from 'react'
import {
  BulletFillIcon,
  FeedATMTCard,
  FeedATMTItem,
  Link,
  StatementIcon,
  Text,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedATMTItemExample = () => {
  const [cardExpanded, setCardExpanded] = useState(false)

  return (
    <FeedATMTItem
      card={
        <FeedATMTCard
          description={{
            body: 'Tutkinnon tai koulutuksen nimi: Influencer Oppilaitos tai koulutuksen järjestäjä: Someron Someopisto Omaehtoisen opiskelun tukemisen edellytys: JTYPL (Laki julkisesta työvoima- ja yrityspalvelusta)',
            summary:
              'Omaehtoisen opiskelun tukemisen kesto 1.7.2023 - 31.12.2023'
          }}
          details={{
            status: {
              icon: (
                <BulletFillIcon
                  color={'success'}
                  size={'md'}
                />
              ),
              text: <Text color={'success'}>Voimassa</Text>
            },
            textRows: [
              {
                content: (
                  <Time
                    date={new Date('2021-11-11')}
                    withYear
                  />
                ),
                label: 'Liittyvä päivä'
              },
              {
                content: (
                  <>
                    <Time
                      date={new Date('2023-7-1')}
                      withYear
                    />{' '}
                    -{' '}
                    <Time
                      date={new Date('2023-12-31')}
                      withYear
                    />
                  </>
                ),
                label: 'Voimassa'
              }
            ]
          }}
          headingComponent={
            <Link
              as={LinkMock}
              to={'#'}
            >
              OM01 Omaehtoinen opiskelu työttömyysetuudella
            </Link>
          }
          headingLevel={2}
          isExpanded={cardExpanded}
          toggleExpanded={() => setCardExpanded(!cardExpanded)}
        />
      }
      icon={<StatementIcon />}
      label={{
        date: new Date('2021-11-12'),
        text: 'Annettu'
      }}
    />
  )
}

export { FeedATMTItemExample }
